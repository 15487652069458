<template>
  <div>
    <v-row style="margin-top: 10%" align="center"
    justify="space-around">
        <v-card
        elevation="2"
        outlined
        style="max-width: 500px">
          <v-progress-linear
            :active="showLoader"
            :indeterminate="true"
            :query="false"
          ></v-progress-linear>
            <v-container style="text-align: center;">
                <h1>Login</h1>
                <v-divider></v-divider>
            </v-container>
            <v-form
                ref="form"
                lazy-validation
                style="padding: 30px"
                v-model="loginFormValid"
            >
                <v-text-field
                ref="email_input"
                v-model="userNameOrEmail"
                :rules="userNameOrEmailRules"
                label="Username or Email"
                v-on:keyup.enter="goToPasswordInput"
                required
                ></v-text-field>

                <v-text-field
                ref="password_input"
                v-model="password"
                :rules="passwordRules"
                v-on:keyup.enter="login"
                label="Password"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                required
                ></v-text-field>
                <v-row justify="center">
                  <v-btn
                  color="blue"
                  class="ma-1 white--text"
                  @click="login()"
                  :disabled="!loginFormValid"
                  >
                  Login
                  </v-btn>
                </v-row>
                <v-row justify="center">
                  <v-btn
                  text
                  @click="forgotMyPassword()"
                  >
                  Forgot My Password
                  </v-btn>
                </v-row>
            </v-form>
        </v-card>
    </v-row>
    <v-container d-flex justify-center mb-6>
      <v-alert
        v-model="showAlert"
        dismissible
        color="red"
        border="left"
        elevation="2"
        colored-border
        icon="mdi-alert-circle-outline"
        max-width="500px"
      >
        Incorrect login details, please try again.
      </v-alert>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Login',
    data: () => ({
      loginFormValid: false,
      showLoader: false,
      showAlert: false,
      showPassword: false,
      userNameOrEmail: '',
      userNameOrEmailRules: [
        v => !!v || 'Username or Email is required'
      ],
      password: '',
      passwordRules: [
        v => !!v || 'Password is required',
      ]
    }),
    mounted: function() {
        this.$refs.email_input.focus();
    },

    methods: {
      forgotMyPassword() {
        this.$store.commit("setForgottenUser", {"userNameOrEmail": this.userNameOrEmail})
        this.$router.push("/password-reset");
      },
      login() {
        this.showLoader = true;
        this.$store.dispatch("login", {"userNameOrEmail": this.userNameOrEmail, "password": this.password})
          .then(() => {
            // Need to assign variable as oauth_link will be nullified.
            let oauth_link = this.$store.getters.getOauthLink;
            if ([null, 'null'].includes(oauth_link)) {
              this.$router.push("/");
            } else {
              this.$store.commit('setOuthLink', null);
              this.$router.push(oauth_link);
            }
          })
          .catch(() => {
            this.showAlert = true;
            this.showLoader = false;
          });
      },
      goToPasswordInput() {
        this.$refs.password_input.focus();
      }
    },
}
</script>

